import 'codemirror/addon/hint/show-hint'
import '../codemirror'
import './detect-language-mode'
import type {Doc, Editor, EditorConfiguration, Position} from 'codemirror'
import CodeMirror from 'codemirror'
import {areCharacterKeyShortcutsEnabled} from '@github-ui/hotkey/keyboard-shortcuts-helper'
import {changeValue} from '@github-ui/form-utils'
// eslint-disable-next-line no-restricted-imports
import {fire} from 'delegated-events'
import {getCodeEditor} from '../code-editor'
import hashChange from '../behaviors/hash-change'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {isMarkdown, pasteHandler, renderLineHandler} from '../codemirror/markdown'
import {DefaultKeyMappings, setupEditorFocusTrap} from '../codemirror/keymappings'
import {emojiComplete} from '../codemirror/emoji'

// The CodeEditor can be used to edit the contents
// of a textarea using the CodeMirror editor. Note that this class
// is generic and not tied to a specific editor instance.
//
// If you're looking for blob editing specific code, like
// the "Preview" button on the blob editor, see blob-editor.js
//
// This class relies on a basic DOM structure existing as defined in
// app/views/editors/_file.html.erb.
//
// Once you have the DOM structure setup this file sets up a CodeEditor for
// every 'js-code-editor' class it finds on the page.
//
//
//  container - The containing DOM element to use when initializing.
//
class CodeEditor {
  container: HTMLElement
  textarea?: HTMLTextAreaElement
  filename?: string
  editor?: Editor
  mergeMode?: boolean
  confirmUnload?: () => string

  constructor(container: HTMLElement) {
    this.container = container
    if (!this.container) {
      return
    }
    this.container.setAttribute('data-editor-loaded', '')
    this.textarea = this.container.querySelector<HTMLTextAreaElement>('.js-code-textarea')!

    this.filename = this.textarea.getAttribute('data-filename') || ''

    const code = this.textarea.value
    const mode = this.textarea.getAttribute('data-codemirror-mode')!
    const codemirrorFixedHeight = this.textarea.getAttribute('data-codemirror-fixed-height') === 'true'

    this.mergeMode = this.textarea.getAttribute('data-merge-mode') === 'true'

    const editorHeight = this.textarea.clientHeight

    this.textarea.style.display = 'none'

    const opts: EditorConfiguration = {
      lineNumbers: true,
      value: code,
      inputStyle: 'contenteditable',
      theme: 'github-light',
    }

    if (this.mergeMode) {
      opts['gutters'] = ['CodeMirror-linenumbers', 'merge-gutter']
    }

    const parent = this.textarea.parentElement!
    this.editor = CodeMirror(parent, opts)

    const dataHotkeyScopeId = this.textarea.getAttribute('data-hotkey-scope-id') || 'code-editor'
    this.editor.getInputField().setAttribute('id', dataHotkeyScopeId)

    if (editorHeight !== 0 && codemirrorFixedHeight) {
      const element = this.container.querySelector('.CodeMirror') as HTMLElement

      if (element) {
        element.style.height = `${editorHeight}px`
      }
    }

    this.setMode(mode)
    this.setupKeyBindings()
    this.setupFormBindings()
    this.setupControlBindings()
    this.setupScrollOnHashChange()
  }

  code(): string {
    return this.editor!.getValue()
  }

  setCode(code: string) {
    this.editor!.setValue(code)
  }

  refresh() {
    this.editor!.refresh()
  }

  focus() {
    this.editor!.focus()
  }

  blur() {
    // eslint-disable-next-line github/no-blur
    this.editor!.getInputField().blur()
  }

  getDocument(): Doc {
    return this.editor!.getDoc()
  }

  on(name: string, handler: (event: Event) => void) {
    this.container.addEventListener(name, handler)
  }

  off(name: string, handler: (event: Event) => void) {
    this.container.removeEventListener(name, handler)
  }

  setDocument(document: Doc) {
    this.editor!.swapDoc(document)

    if (document.modeOption && typeof document.modeOption.name !== 'undefined') {
      // mode is already loaded, no need to set it and load it.
      return
    }

    // set the mode manually so it makes sure the mode gets loaded if it needs to be.
    this.setMode(document.modeOption)
  }

  async loading(loadFunction: () => Promise<unknown>) {
    this.editor!.setOption('readOnly', true)
    await loadFunction()
    window.onbeforeunload = null
    this.editor!.setOption('readOnly', false)
  }

  setMode(currentMode: unknown) {
    let mode = currentMode
    if (this.mergeMode) {
      mode = {name: 'conflict', baseMode: mode, editor: this.editor}
    }

    if (mode === 'text/x-gfm') {
      this.editor!.setOption('mode', {name: 'gfm', gitHubSpice: false})
    } else {
      this.editor!.setOption('mode', mode)
    }

    const assetDragAndDropUI = this.container.querySelector<HTMLElement>('.drag-and-drop')
    if (assetDragAndDropUI) {
      const overrideAssetDragAndDrop = !(mode === 'text/x-gfm' || mode === 'text/x-markdown')
      assetDragAndDropUI.hidden = overrideAssetDragAndDrop
      this.editor!.setOption('dragDrop', overrideAssetDragAndDrop)
    }

    if (mode && !this.mergeMode) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rawMode = CodeMirror.findModeByMIME(mode)
      CodeMirror.autoLoadMode(this.editor!, rawMode.mode)
    }
  }

  setConfirmUnloadMessage(message: string) {
    this.confirmUnload = function () {
      return message
    }
  }

  clearConfirmUnloadMessage() {
    this.confirmUnload = undefined
    window.onbeforeunload = null
  }

  setupFormBindings() {
    const changeHandler = (editor: Editor, changes: unknown) => {
      if (this.confirmUnload) {
        window.onbeforeunload = this.confirmUnload
      }
      changeValue(this.textarea!, this.code())
      if (isMarkdown(editor)) {
        emojiComplete(editor)
      }
      fire(this.container, 'change', {editor, changes})
    }

    this.editor!.on('change', changeHandler)
    this.editor!.on('swapDoc', changeHandler)

    this.editor!.on('renderLine', renderLineHandler)
    this.editor!.on('paste', pasteHandler)

    if (this.confirmUnload) {
      const form = this.textarea!.closest('form')
      if (form == null) {
        return
      }

      form.addEventListener('submit', () => {
        window.onbeforeunload = null
      })
    }
  }

  setupControlBindings() {
    const indentWidthControl = this.container.querySelector<HTMLSelectElement>('.js-code-indent-width')!
    const wrapModeControl = this.container.querySelector<HTMLSelectElement>('.js-code-wrap-mode')!
    const indentModeControl = this.container.querySelector<HTMLSelectElement>('.js-code-indent-mode')!

    this.editor!.setOption('tabSize', parseInt(indentWidthControl.value))
    this.editor!.setOption('indentUnit', parseInt(indentWidthControl.value))
    this.editor!.setOption('lineWrapping', wrapModeControl.value === 'on')
    this.editor!.setOption('indentWithTabs', indentModeControl.value !== 'space')

    indentWidthControl.addEventListener('change', () => {
      this.editor!.setOption('tabSize', parseInt(indentWidthControl.value))
      this.editor!.setOption('indentUnit', parseInt(indentWidthControl.value))
    })

    wrapModeControl.addEventListener('change', () => {
      this.editor!.setOption('lineWrapping', wrapModeControl != null && wrapModeControl.value === 'on')
    })

    indentModeControl.addEventListener('change', () => {
      this.editor!.setOption('indentWithTabs', indentModeControl.value !== 'space')
    })
  }

  setupKeyBindings() {
    this.editor!.addKeyMap(DefaultKeyMappings)
    this.editor!.addKeyMap({
      'Shift-Cmd-P': () => {
        // eslint-disable-next-line github/no-blur
        this.blur()
        fire(this.textarea!, 'codeEditor:preview')
      },
    })

    if (this.textarea && document.querySelector('.focus-trap-banner')) {
      const banner: HTMLElement | null = document.querySelector('.focus-trap-banner')
      const bannerText = banner?.textContent
      this.editor!.setOption('screenReaderLabel', `${bannerText}`)
      setupEditorFocusTrap(this.editor!)
    }

    // We treat `Alt+G` as a character key shortcut because it is equivalent to a special character.
    if (!areCharacterKeyShortcutsEnabled()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete CodeMirror.keyMap.default['Alt-G']
    }
  }

  setupScrollOnHashChange() {
    hashChange(() => {
      const lines = parseLineRange(window.location.hash)

      if (lines.length > 0) {
        this.focus()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.editor!.setCursor({line: lines[0] - 1, ch: 0}, {scroll: true})
      }
    })
  }

  // Mark a range of text with a specific CSS class name
  markText(from: Position, to: Position, className: string) {
    return this.editor!.markText(from, to, {className})
  }
}

// The inline blob editor. It's an instance of CodeEditor
// with some special features, such as "Preview".
//
// Note that the attribute data-github-confirm-unload can be set
// to define the confirm upload message:
//
// No data-github-confirm-unload attribute: The system default
// message will be used. This maintains behavioural
// backwards compatibility, although there will be no additional
// text displayed in the confirmation modal.
//
// 'yes' or 'true': The system default message will be used.
// Passing 'yes' or 'true' is an explicit way to use the default,
// and is recommended.
//
// 'no' or 'false': There will be no confirmation, and the enclosing
// form will have to handle navigation away.
//
// Any other non-empty string: Adds text to the system's default message.

function loadEditor(el: HTMLElement) {
  if (getCodeEditor(el) || el.hasAttribute('data-editor-loaded')) {
    return
  }

  // Skip code mirror on mobile and use a plain old textarea
  if (el.classList.contains('js-mobile-code-editor')) {
    return
  }

  const editor = new CodeEditor(el)
  let message = el.getAttribute('data-github-confirm-unload') || ''
  if (message === 'yes' || message === 'true') {
    message = ''
  }
  if (!(message === 'no' || message === 'false')) {
    editor.setConfirmUnloadMessage(message)
  }
  fire(el, 'codeEditor:ready', {editor})
}

observe('.js-code-editor', {
  constructor: HTMLElement,
  add: loadEditor,
})

// Parse line range string.
//
// str - String range
//
// Examples
//
//     parseLineRange("#L3")
//     # => [3]
//
//     parseLineRange("L3-L5")
//     # => [3, 5]
//
//     parseLineRange("")
//     # => []
//
// Returns an Array pair with 2 Numbers.
export function parseLineRange(str: string) {
  let i
  let len
  const lines = str.match(/#?(?:L|-)(\d+)/gi)
  if (lines) {
    const results = []
    for (i = 0, len = lines.length; i < len; i++) {
      const line = lines[i]!
      results.push(parseInt(line.replace(/\D/g, '')))
    }
    return results
  } else {
    return []
  }
}

// Export CodeEditor type
export {CodeEditor}
