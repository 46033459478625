import type {Editor} from 'codemirror'
import {DISALLOW_LINKYFY_ON_PASTE} from './keymappings'

export const renderLineHandler = (cm: Editor, _line: unknown, element: Element) => {
  // Right-align pargraphs in right-to-left languages when editing Markdown
  if (isMarkdown(cm)) {
    element.setAttribute('dir', 'auto')
  }
}

export const pasteHandler = (cm: Editor, event: ClipboardEvent) => {
  if (!DISALLOW_LINKYFY_ON_PASTE.get(cm)) {
    insertSelectedTextLink(cm, event)
  }
  DISALLOW_LINKYFY_ON_PASTE.set(cm, false)
}

export function isMarkdown(cm: Editor) {
  const mode = cm.getMode().name
  return mode === 'gfm' || mode === 'markdown' || cm.getOption('mode') === 'text/x-markdown'
}

export function insertMdToken(cm: Editor, token: string) {
  // Only enable for markdown editing
  if (!isMarkdown(cm)) return

  const selection = cm.getSelection()
  if (selection) {
    // If the user has selected something, wrap their selection
    cm.replaceSelection(token + selection + token)
  } else {
    // Otherwise, add the token and place them in the middle of it
    const cursor = cm.getCursor()
    // The token (like **, or _) happens on either end of the string, so repeat twice
    cm.replaceRange(token.repeat(2), cursor)
    // Then place the cursor in the middle of each string
    cm.setCursor({
      line: cursor.line,
      ch: cursor.ch + token.length,
    })
  }
}

export function toggleMdList(cm: Editor, type: '-' | '>' | 'ol') {
  // Only enable for markdown editing
  if (!isMarkdown(cm)) return

  const cursor = cm.getCursor('from')
  const to = cm.getCursor('to')
  const selection = cm.getRange({line: cursor.line, ch: 0}, to)
  const prefixRegex = new RegExp(`^${type === 'ol' ? '\\d+\\.' : type}\\s`)

  if (selection) {
    const lines = selection.split('\n')
    const everyLineStartsWithPrefix = lines.every(line => prefixRegex.test(line))
    for (let i = 0; i < lines.length; i++) {
      const lineNumber = cursor.line + i
      if (everyLineStartsWithPrefix) {
        // Remove the prefix from each line
        const match = lines[i]!.match(prefixRegex)
        if (match) {
          cm.replaceRange('', {line: lineNumber, ch: 0}, {line: lineNumber, ch: match[0].length})
        }
      } else {
        // Total number of lines selected, so we can insert the prefix at
        // the beginning of each line
        const marker = `${type === 'ol' ? `${i + 1}.` : type} `
        cm.replaceRange(marker, {line: lineNumber, ch: 0})
      }
    }
  } else {
    const match = cm.getLine(cursor.line).match(prefixRegex)
    if (match) {
      // Remove the prefix from the current line
      cm.replaceRange('', {line: cursor.line, ch: 0}, {line: cursor.line, ch: match[0].length})
    } else {
      // Add the string to the beginning of the line
      const marker = `${type === 'ol' ? `1.` : type} `
      cm.replaceRange(marker, {line: cursor.line, ch: 0})
    }
  }
}

export function insertMdLink(cm: Editor) {
  // Only enable for markdown editing
  if (!isMarkdown(cm)) return

  const selection = cm.getSelection()
  if (selection) {
    // This must be done before `replaceSelection()` is called
    const selections = cm.listSelections()

    // If the user has selected something, wrap their selection
    cm.replaceSelection(`[${selection}](url)`)

    // If there is only one selection and it is all on one line (the common case),
    // set their selection to be `url` in the above string.
    // Otherwise, just leave the cursors where they are after replacing the selections
    if (selections.length === 1 && selections[0]!.from().line === selections[0]!.to().line) {
      const range = selections[0]!
      // The selection may be "forward" (from <= to) or "backward" (from > to)
      const selectionEnd = {
        line: range.from().line,
        ch: Math.max(range.from().ch, range.to().ch),
      }

      cm.setSelection(
        {
          // Add 3 for `[,],(` - characters added to the
          // selected text to offset the cursor
          ch: selectionEnd.ch + 3,
          line: selectionEnd.line,
        },
        {
          // The 3 above, plus `url`
          ch: selectionEnd.ch + 6,
          line: selectionEnd.line,
        },
      )
    }
  } else {
    // Add the string
    const cursor = cm.getCursor()
    cm.replaceRange('[](url)', cursor)
    // Then place the cursor between the square brackets
    cm.setCursor({
      line: cursor.line,
      ch: cursor.ch + 1,
    })
  }
}

// https://github.com/github/special-projects/issues/966
// cmd/ctrl+ shift + v pastes unformatted and cmd/ctrl + v formatted
function insertSelectedTextLink(cm: Editor, event: ClipboardEvent) {
  // Only enable for markdown editing
  if (!isMarkdown(cm)) return

  const selection = cm.getSelection()
  const dataTransfer = event.clipboardData
  const markdownLinkSyntaxChars = 4 // 4 = length of `[]()`

  if (selection && dataTransfer) {
    const pasteLink = dataTransfer.getData('text/plain')

    if (!isURL(pasteLink)) return

    if (pasteLink) {
      const cursor = cm.getCursor()
      cm.replaceSelection(`[${selection}](${pasteLink})`)
      cm.setCursor({
        line: cursor.line,
        ch: cursor.ch + pasteLink.length + markdownLinkSyntaxChars,
      })
      event.preventDefault()
    }
  }
}

function isURL(url: string): boolean {
  return /^https?:\/\//i.test(url)
}
