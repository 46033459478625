import 'codemirror/addon/hint/show-hint'
import CodeMirror from 'codemirror'
import type {Editor, Hint, Hints, Position} from 'codemirror'
import memoize from '@github/memoize'

const emojiRequest = memoize(async function getEmojis() {
  const request = new Request('/autocomplete/emojis_for_editor', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })

  const res = await fetch(request)
  if (res.status !== 200) {
    throw new Error(`emoji request failed with a ${res.status}`)
  }

  const emojiList = []
  const emojiData = await res.json()
  for (const emojiName of Object.keys(emojiData)) {
    const emojiObject = {
      text: `${emojiName}:`,
      render: (element: HTMLElement) => {
        const emojiOption = document.createElement('img')
        emojiOption.className = 'emoji emoji-result'
        emojiOption.height = 20
        emojiOption.width = 20
        emojiOption.alt = `:${emojiName}:`
        emojiOption.src = emojiData[emojiName][0]
        emojiOption.setAttribute('async', '')

        element.appendChild(emojiOption)
        element.appendChild(document.createTextNode(` ${emojiName}`))
      },
      hint: (cm: Editor, data: Hints, completion: Hint) => {
        if (emojiData[emojiName].length === 2) {
          const currentPos = completion.from || data.from
          const updatedPos = {ch: currentPos.ch - 1, line: currentPos.line}
          cm.replaceRange(emojiData[emojiName][1], updatedPos, completion.to || data.to, 'complete')
        } else {
          cm.replaceRange(completion.text, completion.from || data.from, completion.to || data.to, 'complete')
        }
      },
    }
    emojiList.push(emojiObject)
  }
  return emojiList
})

export async function emojiComplete(cm: Editor) {
  const cursor = cm.getCursor()

  if (isTokenColon(cm, cursor) && !colonShowsEmojiPicker(cm, cursor)) {
    return
  }

  try {
    const emojiList = await emojiRequest()
    CodeMirror.showHint(
      cm,
      () => {
        const cur = cm.getCursor()
        const token = cm.getTokenAt(cur)
        const end = cur.ch
        let ch = cur.ch
        const line = cur.line
        let currentWord = token.string

        while (ch-- > -1) {
          const t = cm.getTokenAt({ch, line}).string

          //check if the character before the : token is empty (new line) or space
          const prevCh = ch - 1
          const previousChar = cm.getTokenAt({ch: prevCh, line}).string

          if (t === ':' && isSpaceOrEmpty(previousChar)) {
            const filteredList = emojiList.filter(item => {
              return item.text.indexOf(currentWord) !== -1
            })
            return {
              list: filteredList,
              from: CodeMirror.Pos(line, ch),
              to: CodeMirror.Pos(line, end),
            }
          }
          currentWord = t + currentWord
        }
        return {
          list: [],
          from: CodeMirror.Pos(line, 0),
          to: CodeMirror.Pos(line, end),
        }
      },
      {completeSingle: false},
    )
  } catch (e) {
    // silently fail, try to fetch emojis on next emojiComplete
  }
}

function isFirstCharacterOnLine(cursor: Position) {
  // expecting the colon to actually be first character
  // so cursor is actually second character
  return cursor.ch === 1
}

function isPreviousCharacterSpace(cm: Editor, cursor: Position) {
  const previousChar = previousCharacter(cm, cursor)
  return /\s/.test(previousChar)
}

function isSpaceOrEmpty(character: string) {
  return /\s/.test(character) || character === ''
}

function isTokenColon(cm: Editor, cursor: Position) {
  const token = cm.getTokenAt(cursor)
  return token.string === ':'
}

function previousCharacter(cm: Editor, cursor: Position) {
  return cm.getRange({line: cursor.line, ch: cursor.ch - 2}, {line: cursor.line, ch: cursor.ch - 1})
}

function colonShowsEmojiPicker(cm: Editor, cursor: Position) {
  return isFirstCharacterOnLine(cursor) || isPreviousCharacterSpace(cm, cursor)
}
